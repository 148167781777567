// scroll func
$(function () {
  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop();
    if (scrollTop > 80) {
      $('nav.navbar').addClass("is-scroll-down");
    } else {
      $('nav.navbar').removeClass("is-scroll-down");
    }
  });
});

// Off Canvas
$('[data-toggle="offcanvas"]').on('click', function () {
  $('.offcanvas-collapse').toggleClass('open');
  $('body').toggleClass('open-navbar');
})
// Tooltip
$(function () {
  $('[data-toggle="tooltip"]').tooltip();
})
// close navbar collapse
$('.site-overlay').on('click', function () {
  $('.navbar-toggler').click();
})
$(document).keyup(function (e) {
  if (e.keyCode === 27) {
    $('.navbar-toggler').click();
  }
});
// Make Images in Texarea Responsive
// if (!$("#textarea img").hasClass("img-fluid")) {
//     $("#textarea img").addClass("img-fluid");
// }

// Clean up Tables in Texarea
$("table").removeAttr("style").removeAttr("border");
$("td").removeAttr("style");
$("thead").addClass("bg-secondary text-white");

// Make iFrame Responsive
// $( "iframe" ).wrap( "<div class='embed-responsive embed-responsive-16by9'></div>" );

//Make Social Mail ikon title "mailto:"
$('.socialmedia .fa-envelope-square').each(function () {
  var oldMailTitle = $(this).attr("title");
  var newMailTitle = oldMailTitle.replace("http://", "mailto:");
  $(this).attr("title", newMailTitle);
});

//Make Social Mail ikon href "mailto:" link
$('.socialmedia .fa-envelope-square').parent().each(function () {
  var oldUrl = $(this).attr("href");
  var newUrl = oldUrl.replace("http://", "mailto:");
  $(this).attr("href", newUrl);
});

// Add Tab hash to URL
$(function () {
  var hash = window.location.hash;
  hash && $('ul.nav a[href="' + hash + '"]').tab('show');
  $('.nav-item a').click(function (e) {
    $(this).tab('show');
    var scrollmem = $('body').scrollTop() || $('html').scrollTop();
    window.location.hash = this.hash;
    $('html,body').scrollTop(scrollmem);
  });
});

// Shrinking Navbar
/*
(new IntersectionObserver(function(e,o){
	if (e[0].intersectionRatio > 0){
		document.documentElement.removeAttribute('id');
	} else {
		document.documentElement.setAttribute('id','nav-at-top');
	};
})).observe(document.querySelector('#nav-anker'));

//  Scroll to top 
$('#scrolltop').on("click",function() {
	$('html, body').animate({ scrollTop: 0 }, 'slow', function () {
	   console.log('Top'); 
	});
});
*/


// Fake Login /////////////////////////////////////////////////////////////

// document.querySelector('html').classList.add("overflowOn");


// if (sessionStorage.getItem('Form') == 'oneTime'){
// 	document.querySelector('.fake-form-wrapper').style.display = "none";
// 	document.querySelector('html').classList.remove("overflowOn");
// }

// document.querySelector('#submit').addEventListener('click', function(e) {
// 	e.preventDefault();
// 	// do something
// 	var validEmail = document.querySelector('#username').value === 'alt';
// 	var validPass  = document.querySelector('#password').value === '4444';
// 	if ( validEmail === true && validPass === true) {
// 		document.querySelector('.fake-form-wrapper').style.display = "none";
// 		document.querySelector('html').classList.remove("overflowOn");
// 		sessionStorage.setItem('Form','oneTime');	
// 	}
// 	else {
// 		document.querySelector('.error').style.display = "block";
// 	}
// 	return false;
// });
// end Fake Login /////////////////////////////////////////////////////////